<template>
  <div class="widget-accordion-wrap">
    <div :class="isActionAccordion
      ? 'widget-accordion-item show'
      : 'widget-accordion-item'
      ">
      <div @click="actionAccordion()" class="widget-accordion-head">
        <span>NAVIGATION</span>
        <span class="icon">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A" />
          </svg>
        </span>
      </div>
      <div class="widget-accordion-body">
        <div v-for="(data, index) in column.propertiesArray[
          column.properties.selectedVariationIndex
        ].navigations" :key="index" class="widget-input-item add-new-navigation">
          <div class="d-flex justify-content-between align-items-center">
            <div class="w-100--28">
              <div class="d-flex justify-content-between align-items-center">
                <input v-model="data.name" type="text" class="b-form-field w-160 radius-8" />
                <div class="d-flex buttons ms-auto ms-1">
                  <button @click="optionButtonClickHandler(index, 'isBold')" :class="data?.isBold
                    ? 'k-button-solid-base btn active'
                    : 'k-button-solid-base btn'
                    " title="Bold">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M4 3H7.61875C8.40938 3 9 3.03438 9.3875 3.1C9.775 3.16563 10.125 3.30312 10.4313 3.5125C10.7375 3.72187 10.9937 4 11.2 4.34687C11.4062 4.69375 11.5062 5.08437 11.5062 5.51562C11.5062 5.98438 11.3781 6.4125 11.1281 6.80312C10.875 7.19375 10.5312 7.4875 10.1 7.68437C10.7094 7.8625 11.1781 8.16562 11.5062 8.59062C11.8344 9.01562 11.9969 9.52188 11.9969 10.0969C11.9969 10.5531 11.8906 10.9938 11.6781 11.4219C11.4688 11.8531 11.1781 12.1969 10.8125 12.4531C10.4469 12.7094 9.99687 12.8688 9.45937 12.9281C9.12187 12.9656 8.3125 12.9875 7.02187 12.9969H4V3ZM6 4.66562V6.97813H6.96563C7.75312 6.97813 8.24062 6.96563 8.43125 6.94375C8.77813 6.90313 9.04688 6.78125 9.24687 6.58437C9.44375 6.3875 9.54375 6.125 9.54375 5.80312C9.54375 5.49375 9.45937 5.24062 9.2875 5.05C9.11562 4.85625 8.8625 4.7375 8.525 4.7C8.325 4.67813 7.75 4.66562 6.8 4.66562H6ZM6 11.3188V8.64375L7.27187 8.64062C8.19063 8.64062 8.7875 8.6875 9.0625 8.78438C9.3375 8.87813 9.54688 9.03125 9.69375 9.24062C9.84062 9.45 9.9125 9.70625 9.9125 10.0062C9.9125 10.3625 9.82188 10.6438 9.63125 10.8562C9.44062 11.0688 9.19688 11.2 8.89687 11.2563C8.7 11.2969 8.24062 11.3188 7.5125 11.3188H6Z"
                        fill="#24243A" />
                    </svg>
                  </button>
                  <button @click="optionButtonClickHandler(index, 'isItalic')" :class="data?.isItalic
                    ? 'k-button-solid-base border-0 btn active'
                    : 'k-button-solid-base border-0 btn'
                    " title="Italic">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.7875 4L12 3H10.0625H8.0625H6.2125L6 4H7.85L6.15 12H4.2125L4 13H5.9375H7.9375H9.7875L10 12H8.15L9.85 4H11.7875Z"
                        fill="#24243A" />
                    </svg>
                  </button>
                  <ColorPicker :gradient-settings="{ opacity: false }" v-model="data.color" />
                </div>
              </div>
              <div class="mt-12">
                <input v-model="data.url" type="text" class="b-form-field w-100 radius-8" placeholder=" Provide URL " />
              </div>
            </div>
            <kbutton v-if="column.propertiesArray[
              column.properties.selectedVariationIndex
            ].navigations?.length > 1" @click="deleteNavigationHandler(index)" :fill-mode="'flat'"
              :class="'border-0 p-0 h-18 w-18'" :theme-color="'primary'">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5 4.5L4.5 13.5M4.5 4.5L13.5 13.5" stroke="#101828" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </kbutton>
          </div>
        </div>

        <div class="widget-input-item border-bottom-0">
          <kbutton :fill-mode="'outline'" :class="'h-38 font-w-500 border-normal-1 base-color bg-white hvr-bg-primary'"
            :theme-color="'primary'" @click="addNewNavigationHandler">
            Add New
          </kbutton>
        </div>

        <!-- <div class="widget-input-item border-bottom-0">
          <div class="d-flex justify-content-between align-items-center">
            <label class="b-form-label mb-0">Table Size</label>
            <div class="input-box-with-name d-flex">
              <input class="k-input h-38 w-80 mb-0" width="5px" type="number" :value="column.propertiesArray[
                column.properties.selectedVariationIndex
              ].tableWidth
                " @focusout="onChangeTableWidth" />
              <span class="box-name">PX</span>
            </div>
          </div>
        </div> -->
        <div class="widget-input-item border-bottom-0">
          <div class="d-flex justify-content-between align-items-center">
            <label class="b-form-label mb-0">Space Between Columns</label>
            <div class="input-box-with-name d-flex">
              <input class="k-input h-38 w-80 mb-0" width="5px" type="number" :value="column.propertiesArray[
                column.properties.selectedVariationIndex
              ].columnSpace
                " @focusout="onChangeColumnSpace" />
              <span class="box-name">PX</span>
            </div>
          </div>
        </div>
        <div class="widget-input-item border-bottom-0 alignment">
          <alignmentComponent :column="column" :columnType="columnType" :index="index" :childIndex="childIndex" />
        </div>
        <div class="widget-input-item border-bottom-0 d-flex justify-content-between align-items-center">
          <label class="b-form-label">Font</label>

          <AppDropdownList :class="'drop-icon-2'" :searchable="true" :dataItems="fontList" :textField="'text'"
            :dataItemKey="'value'" width="230px" v-model="this.draggable_editor_data[this.index][this.columnType][
              this.childIndex
            ].propertiesArray[this.column.properties.selectedVariationIndex]
              .font_family
              " @updateDropdownValue="(value) =>
              (this.draggable_editor_data[this.index][this.columnType][
                this.childIndex
              ].propertiesArray[
                this.column.properties.selectedVariationIndex
              ].font_family = value)
                " />
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <label class="b-form-label mb-0">Background Color</label>
          <ColorPicker :show-clear-button="false" :gradient-settings="{ opacity: false }" v-model="column.propertiesArray[
            column.properties.selectedVariationIndex
          ].style.backgroundColor" />
        </div>
      </div>
    </div>
    <div :class="isGeneralAccordion
      ? 'widget-accordion-item show'
      : 'widget-accordion-item'
      ">
      <div @click="generalAccordion()" class="widget-accordion-head">
        <span>GENERAL</span>
        <span class="icon">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A" />
          </svg>
        </span>
      </div>
      <generalAccordionComponent :column="column" :columnType="columnType" :index="index" :childIndex="childIndex"
        :except="[]" />
    </div>
    <div :class="isRestrictContentToAccordion
      ? 'widget-accordion-item show'
      : 'widget-accordion-item'
      ">
      <div @click="restrictContentToAccordion()" class="widget-accordion-head">
        <span>RESTRICT CONTENT TO</span>
        <span class="icon">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
              fill="#24243A" />
          </svg>
        </span>
      </div>
      <RestrictContentToComponent :column="column" :columnType="columnType" :index="index" :childIndex="childIndex" />
    </div>
  </div>
</template>

<script>
import { Input, Switch } from "@progress/kendo-vue-inputs";
import { ColorPicker } from "@progress/kendo-vue-inputs";
import borderWidthComponent from "../../common-components/borderWidthComponent.vue";
import heigthSliderComponent from "../../common-components/heigthSliderComponent.vue";
import alignmentComponent from "../../common-components/alignmentComponent.vue";
import { MultiSelect } from "@progress/kendo-vue-dropdowns";
import { Slider } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import generalAccordionComponent from "../../common-components/generalAccordionComponent.vue";
import paddingComponent from "../../common-components/paddingComponent.vue";
import { pxInputHandler } from "../../helpers/utility";
import RestrictContentToComponent from "../../common-components/restrictContentToComponent.vue";
import widthSliderComponent from "../../common-components/widthSliderComponent.vue";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import fontList from "../../helpers/fontList";
import { filterBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-vue-buttons";
import AppDropdownList from "@/components/common/AppDropdownList/AppDropdownList.vue";

export default {
  inject: ["draggable_editor_data"],
  name: "navigationController",
  components: {
    KInput: Input,
    Switch,
    ColorPicker,
    paddingComponent,
    multiselect: MultiSelect,
    slider: Slider,
    checkbox: Checkbox,
    borderWidthComponent,
    heigthSliderComponent,
    alignmentComponent,
    generalAccordionComponent,
    RestrictContentToComponent,
    widthSliderComponent,
    DropDownList,
    kbutton: Button,
    AppDropdownList,
  },
  data() {
    return {
      isActionAccordion: true,
      isButtonOptionsAccordion: true,
      isSpacingAccordion: true,
      isGeneralAccordion: true,
      isResponsiveDesignlAccordion: true,
      isRestrictContentToAccordion: true,
      isBoldCheck: false,
      isItalicCheck: false,
      isUnderLineCheck: false,
      isStrikeThrough: false,
      autoWidthValue: 100,
      isAutoWidthCheck: false,
      isLeftAlign: false,
      isRightAlign: false,
      iscenterAlign: false,
      fontSizeInput: 16,
      lineHightSizeInput: 100,

      copyTags: [],
      fontList: fontList,
    };
  },
  props: {
    column: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
  },
  computed: {
    macros() {
      return this.$store.state.commonEmailCampaign
        .emailCampaignTemplateMacroLists;
    },
    mainContainerWidth() {
      return this.$store.state.common.mainContainerWidth;
    },
  },
  mounted() {
    const boldType =
      this.draggable_editor_data[this.index][this.columnType][this.childIndex]
        .propertiesArray[this.column.properties.selectedVariationIndex].style
        .fontWeight;
    const ItalicType =
      this.draggable_editor_data[this.index][this.columnType][this.childIndex]
        .propertiesArray[this.column.properties.selectedVariationIndex].style
        .fontStyle;
    const underlineStrikeType =
      this.draggable_editor_data[this.index][this.columnType][this.childIndex]
        .propertiesArray[this.column.properties.selectedVariationIndex].style
        .textDecoration;

    this.isBoldCheck = boldType == "700" ? true : false;
    this.isItalicCheck = ItalicType == "italic" ? true : false;
    this.isUnderLineCheck = underlineStrikeType == "underline" ? true : false;
    this.isStrikeThrough = underlineStrikeType == "line-through" ? true : false;

    if (underlineStrikeType == "line-through underline") {
      this.isUnderLineCheck = true;
      this.isStrikeThrough = true;
    }

    this.isAutoWidthCheck =
      this.column.propertiesArray[
        this.column.properties.selectedVariationIndex
      ].isAutoWidthCheck;

    if (
      this.column.propertiesArray[this.column.properties.selectedVariationIndex]
        .style.width != "auto" &&
      !this.isAutoWidthCheck
    ) {
      this.autoWidthValue =
        this.column.propertiesArray[
          this.column.properties.selectedVariationIndex
        ].style.width.split("%")[0];
    }
    this.fontSizeInput =
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].style.fontSize.split("p")[0];
    this.lineHightSizeInput =
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].style.lineHeight.split("p")[0];
  },

  methods: {
    deleteNavigationHandler(index) {
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].navigations.splice(index, 1);
    },
    onChangeColumnSpace(event) {
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].columnSpace =
        event?.target?.value > 0
          ? event?.target?.value > 100
            ? 100
            : event?.target?.value
          : 0 || 0;
    },
    onChangeTableWidth(event) {
      const newValue = event?.target?.value;
      this.draggable_editor_data[this.index][this.columnType][this.childIndex]
        .propertiesArray[this.column.properties.selectedVariationIndex].tableWidth =
        newValue > this.mainContainerWidth ? this.mainContainerWidth : newValue;
    },
    addNewNavigationHandler() {
      if (
        this.draggable_editor_data[this.index][this.columnType][this.childIndex]
          .propertiesArray[this.column.properties.selectedVariationIndex]
          .navigations.length >= 7
      ) {
        this.$store.commit("SHOW_NOTIFICATION", {
          message: "Limit reached! You can add up to 7 navigation items",
          type: "warning",
        });
        return;
      }

      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].navigations.push({
        name: "New",
        url: "",
        color: "#000000",
        isBold: false,
        isItalic: false,
      });
    },
    optionButtonClickHandler(index, optionType) {
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].navigations[index][optionType] =
        !this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[this.column.properties.selectedVariationIndex]
          .navigations[index][optionType];
    },

    onChangeIncludeCampaignId(event) {
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].isIncludeCampaignId = event.target.value;
    },
    onDropdownOpenHandler() {
      this.copyTags = structuredClone(this.macros);
    },
    filterChange(event) {
      this.copyTags = this.filterData(event.filter);
    },
    filterData(filter) {
      const data = this.macros.slice();
      return filterBy(data, filter);
    },
    onChange(event) {
      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[
        this.column.properties.selectedVariationIndex
      ].selectedTag = event.value;
    },
    restrictContentToAccordion() {
      this.isRestrictContentToAccordion = !this.isRestrictContentToAccordion;
    },
    actionAccordion() {
      this.isActionAccordion = !this.isActionAccordion;
    },
    buttonOptionsAccordion() {
      this.isButtonOptionsAccordion = !this.isButtonOptionsAccordion;
    },
    spacingAccordion() {
      this.isSpacingAccordion = !this.isSpacingAccordion;
    },
    generalAccordion() {
      this.isGeneralAccordion = !this.isGeneralAccordion;
    },
    responsiveDesignlAccordion() {
      this.isResponsiveDesignlAccordion = !this.isResponsiveDesignlAccordion;
    },

    buttonStype(index, childIndex, columnType, buttonType, variationIndex) {
      if (buttonType == "rectangle") {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].style.borderRadius = "0px";
      }
      if (buttonType == "roundedCorners") {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].style.borderRadius = "8px";
      }
      if (buttonType == "rounded") {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].style.borderRadius = "50px";
      }
    },

    minusButtonFrontChange(e) {
      this.emitFunction("minusButtonFront", e);
    },
    minusButtonHigthChange(e) {
      this.emitFunction("minusButtonLineHight", e);
    },
    plusButtonFrontChange(e) {
      this.emitFunction("plusButtonFront", e);
    },
    plusButtonLineHightChange(e) {
      this.emitFunction("plusButtonLineHight", e);
    },
    boldButton(e) {
      console.log(e, "c89");
      // this.isBoldCheck = !this.isBoldCheck;
      // this.emitFunction("boldButton", e, this.isBoldCheck);
    },
    italicButton(e) {
      this.isItalicCheck = !this.isItalicCheck;
      this.emitFunction("italicButton", e, this.isItalicCheck);
    },
    underLineButton(e) {
      this.isUnderLineCheck = !this.isUnderLineCheck;
      this.emitFunction(
        "underLineButton",
        e,
        this.isUnderLineCheck,
        this.isStrikeThrough
      );
    },
    //strikeThrough
    strikeThrough(e) {
      this.isStrikeThrough = !this.isStrikeThrough;
      this.emitFunction(
        "strikeThrough",
        e,
        this.isStrikeThrough,
        this.isUnderLineCheck
      );
    },

    widthSlider(e) {
      this.emitFunction("widthSlider", e, e.value, false);
      this.autoWidthValue = e.value;
    },
    autoWidthSet(e) {
      this.isAutoWidthCheck = !this.isAutoWidthCheck;
      if (!this.isAutoWidthCheck) {
        this.autoWidthValue = "100";
        this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[
          this.column.properties.selectedVariationIndex
        ].style.width = "100%";
      }
      this.emitFunction("autoWidthSet", e, this.isAutoWidthCheck, false);
    },
    debounceInput(event) {
      this.fontSizeInput = event.target.value;
      this.emitFunction("marginInput", event);
    },
    debounceLineHightInput(event) {
      this.lineHightSizeInput = event.target.value;
      this.emitFunction("marginLineHightInput", event);
    },

    emitFunction(type, e, isFirstValue, isSecondValue) {
      this.buttonControllerEmit(
        type,
        e,
        this.index,
        this.childIndex,
        this.columnType,
        isFirstValue,
        isSecondValue,
        this.column.properties.selectedVariationIndex
      );
    },

    buttonControllerEmit(
      type,
      e,
      index,
      childIndex,
      columnType,
      isFirstValue,
      isSecondValue,
      variationIndex
    ) {
      if (type == "minusButtonFront") {
        var onlyPxInt =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.fontSize.split("p");
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.fontSize = onlyPxInt[0];
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.fontSize -= 1;
        if (
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray[variationIndex].style.fontSize < 0
        ) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.fontSize = "16";
        }
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.fontSize =
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray[variationIndex].style.fontSize + "px";
        this.fontSizeInput =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.fontSize.split("p")[0];
      }
      if (type == "minusButtonLineHight") {
        var onlyPxInt =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.lineHeight.split("p");
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.lineHeight = onlyPxInt[0];
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.lineHeight -= 1;
        if (
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray[variationIndex].style.lineHeight < 0
        ) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.lineHeight = "25";
        }
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.lineHeight =
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray[variationIndex].style.lineHeight + "px";
        this.lineHightSizeInput =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.lineHeight.split("p")[0];
      }
      if (type == "plusButtonFront") {
        var onlyPxInt =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.fontSize.split("p");
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.fontSize = parseInt(
          onlyPxInt[0]
        );
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.fontSize =
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray[variationIndex].style.fontSize + 1;
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.fontSize =
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray[variationIndex].style.fontSize + "px";
        this.fontSizeInput =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.fontSize.split("p")[0];
      }
      if (type == "plusButtonLineHight") {
        var onlyPxInt =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.lineHeight.split("p");
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.lineHeight = parseInt(
          onlyPxInt[0]
        );
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.lineHeight =
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray[variationIndex].style.lineHeight + 1;
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.lineHeight =
          this.draggable_editor_data[index][columnType][childIndex]
            .propertiesArray[variationIndex].style.lineHeight + "px";
        this.lineHightSizeInput =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.lineHeight.split("p")[0];
      }
      if (type == "boldButton") {
        if (isFirstValue) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.fontWeight = "700";
        } else {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.fontWeight = "";
        }
      }
      if (type == "italicButton") {
        if (isFirstValue) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.fontStyle = "italic";
        } else {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.fontStyle = "";
        }
      }
      if (type == "underLineButton") {
        if (isSecondValue && isFirstValue) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.textDecoration =
            "line-through underline";
        } else if (isFirstValue) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.textDecoration = "underline";
        } else if (isSecondValue) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.textDecoration =
            "line-through";
        } else {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.textDecoration = "";
        }
      }
      if (type == "strikeThrough") {
        if (isFirstValue && isSecondValue) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.textDecoration =
            "line-through underline";
        } else if (isFirstValue) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.textDecoration =
            "line-through";
        } else if (isSecondValue) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.textDecoration = "underline";
        } else {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.textDecoration = "";
        }
      }
      if (type == "widthSlider") {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.width = isFirstValue + "%";
      }
      if (type == "autoWidthSet") {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].isAutoWidthCheck = isFirstValue;
        if (!isFirstValue) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.width = "100%";
        }
        if (isFirstValue) {
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.width = "auto";
        }
      }
      if (type == "marginInput") {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.fontSize = pxInputHandler(
          e.target.value
        );
        this.fontSizeInput =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.fontSize.split("p")[0];
      }
      if (type == "marginLineHightInput") {
        this.draggable_editor_data[index][columnType][
          childIndex
        ].propertiesArray[variationIndex].style.lineHeight = pxInputHandler(
          e.target.value
        );
        this.lineHightSizeInput =
          this.draggable_editor_data[index][columnType][
            childIndex
          ].propertiesArray[variationIndex].style.lineHeight.split("p")[0];
      }
    },
  },
};
</script>

<style scoped></style>
