<template>
  <div class="d-flex justify-content-between align-items-center">
    <label class="b-form-label mb-0">{{ label }}</label>
    <div class="buttons">
      <button
        v-for="option in options"
        :key="option.id"
        @click="optionsHandler(option.key)"
        :class="
          this.draggable_editor_data[this.index][this.columnType][
            this.childIndex
          ].propertiesArray[this.column.properties.selectedVariationIndex][
            this.parentKey
          ][option.key]
            ? 'k-button-solid-base border-0 active'
            : 'k-button-solid-base border-0'
        "
        :title="option.title"
      >
        <component :is="option.icon" />
      </button>
    </div>
  </div>
</template>

<script>
import alignmentLeft from "@/components/svg-icons/alignmentLeft.vue";
import alignmentCenter from "@/components/svg-icons/alignmentCenter.vue";
import alignmentRight from "@/components/svg-icons/alignmentRight.vue";
import alignmentIndent from "@/components/svg-icons/alignmentIndent.vue";
import boldIcon from "@/components/svg-icons/boldIcon.vue";
import italicIcon from "@/components/svg-icons/italicIcon.vue";
import underLineIcon from "@/components/svg-icons/underLineIcon.vue";
import strikethroughIcon from "@/components/svg-icons/strikethroughIcon.vue";
import orientationHorizontal from "@/components/svg-icons/orientationHorizontal.vue";
import orientationVerticalLeft from "@/components/svg-icons/orientationVerticalLeft.vue";
import orientationVerticalRight from "@/components/svg-icons/orientationVerticalRight.vue";

export default {
  inject: ["draggable_editor_data"],
  name: "alignmentComponent",
  components: {
    alignmentLeft,
    alignmentCenter,
    alignmentRight,
    alignmentIndent,
    boldIcon,
    italicIcon,
    underLineIcon,
    strikethroughIcon,
    orientationHorizontal,
    orientationVerticalLeft,
    orientationVerticalRight,
  },
  props: {
    column: Object,
    index: Number,
    childIndex: Number,
    columnType: String,
    styleCssClass: Array,
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    multiSelect: {
      type: Boolean,
      default: true,
    },
    noneSelected: {
      type: Boolean,
      default: false,
    },
    parentKey: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "Alignment",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    optionsHandler(keyName) {
      if (!this.multiSelect) {
        this.options.forEach((option) => {
          if (option.key !== keyName) {
            this.draggable_editor_data[this.index][this.columnType][
              this.childIndex
            ].propertiesArray[this.column.properties.selectedVariationIndex][
              this.parentKey
            ][option.key] = false;
          }
        });
      }

      this.draggable_editor_data[this.index][this.columnType][
        this.childIndex
      ].propertiesArray[this.column.properties.selectedVariationIndex][
        this.parentKey
      ][keyName] =
        !this.draggable_editor_data[this.index][this.columnType][
          this.childIndex
        ].propertiesArray[this.column.properties.selectedVariationIndex][
          this.parentKey
        ][keyName];

      if (!this.noneSelected) {
        let atLeastOneSelected = this.options.some(
          (option) =>
            this.draggable_editor_data[this.index][this.columnType][
              this.childIndex
            ].propertiesArray[this.column.properties.selectedVariationIndex][
              this.parentKey
            ][option.key]
        );

        if (!atLeastOneSelected) {
          this.draggable_editor_data[this.index][this.columnType][
            this.childIndex
          ].propertiesArray[this.column.properties.selectedVariationIndex][
            this.parentKey
          ][keyName] = true;
        }
        //hotFix
        if (keyName === "isHorizontal") {
          this.draggable_editor_data[this.index][this.columnType][
            this.childIndex
          ].propertiesArray[
            this.column.properties.selectedVariationIndex
          ].productsPerRow = 3;
        }
      }
    },
  },
};
</script>

<style scoped></style>
